import React, {useMemo} from 'react';

import {Tag, TagLabel, TagLeftIcon, TagProps} from '@chakra-ui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {TAGS_MAP} from 'utils/constants';

interface CourseRoleTagProps extends TagProps {
  name: string;
  slug: string;
  withBorder?: boolean;
}

const CourseRoleTag: React.FC<CourseRoleTagProps> = ({name, slug, withBorder}) => {
  const map = TAGS_MAP.find((t) => t.slug === slug);
  const primaryColor = map?.primaryColor || '#fff';
  const icon = map?.icon || faStar;

  const padding = useMemo(() => {
    return withBorder ? '8px 15px' : 0;
  }, [withBorder]);

  const border = useMemo(() => {
    return withBorder ? `1px solid ${primaryColor}` : 'none';
  }, [withBorder, primaryColor]);

  return (
    <Tag
      backgroundColor="transparent"
      color={primaryColor}
      padding={padding}
      border={border}
      borderRadius={20}
    >
      <TagLeftIcon as={FontAwesomeIcon} icon={icon} />
      <TagLabel fontSize="12px" textTransform="uppercase">
        {name}
      </TagLabel>
    </Tag>
  );
};

export default CourseRoleTag;
