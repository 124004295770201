import {Heading, HStack, Link as CLink, Stack, Text, VStack} from '@chakra-ui/react';
import {EnrollButton} from 'components/Buttons';
import CourseRoleTag from 'components/CourseRoleTag';
import {useAnalytics} from 'context/AnalyticsWebContext';
import {EnrollmentContextProvider} from 'context/EnrollmentContext';
import {
  Cohorts,
  CohortTypeEnum,
  CourseEnrollmentStatusEnum,
  Courses,
  CourseTypeEnum,
  useGetSubordinateEnrollmentsLazyQuery
} from 'generated/graphql';
import Link from 'next/link';
import {useCallback, useEffect, useMemo} from 'react';
import {LIMITED_SEATS_THRESHOLD, TIME_FORMAT} from 'utils/constants';
import {
  buildDateStringWithTimeFromStartDateAndStartTime,
  formatDate,
  timeDurationString
} from 'utils/dates/formatDate';
import {coursePath} from 'utils/routeFactory';
import {useSession} from '../../../context/SessionContext';
import {TeamEnrollmentStatus} from './TeamEnrollmentStatus';

interface CohortCardProps {
  cohort: Cohorts;
  course?: Courses;
  linkToCourse?: boolean;
  hideTags?: boolean;
  onCloseTeamEnrollModal?: () => void;
  managerView?: boolean;
}

export default function CohortCard(props: CohortCardProps) {
  const {data: session} = useSession();
  const analyticsWebClient = useAnalytics();
  const isWaitlistEnabled = analyticsWebClient?.checkFeatureFlag('enrollment-waitlist');

  const userAbilities = session?.user.abilities;

  const [
    fetchSubordinateEnrollments,
    {data: subordinateEnrollmentsData, refetch: refetchSubordinateEnrollments}
  ] = useGetSubordinateEnrollmentsLazyQuery({
    variables: {
      cohortId: props.cohort.id
    }
  });

  const handleCloseTeamEnrollModal = useCallback(() => {
    refetchSubordinateEnrollments();
    props.onCloseTeamEnrollModal?.();
  }, [props, refetchSubordinateEnrollments]);

  const course = useMemo(() => {
    if (props.cohort.course) {
      return props.cohort.course;
    } else {
      return props.course;
    }
  }, [props.course, props.cohort]);

  const tagsComponents = useMemo(
    () =>
      course?.tags?.map(({tag}) =>
        tag ? <CourseRoleTag key={tag.name} name={tag.name} slug={tag.slug} /> : null
      ),
    [course?.tags]
  );

  const classesDescription = useMemo(() => {
    const firstClass = course?.classes?.filter((cls) => cls.isLive == true)[0];
    const durationDescription = timeDurationString(firstClass?.durationInMinutes || 0);

    if (course?.type === CourseTypeEnum.LIGHTNING_SESSION) {
      return durationDescription;
    }

    let finalLabel = '';

    if (durationDescription) {
      finalLabel += durationDescription;
    }

    if (course?.timeLengthInClasses) {
      if (finalLabel) {
        finalLabel += ' x ';
      }
      const classLabel = course?.timeLengthInClasses === 1 ? 'class' : 'classes';

      finalLabel += `${course?.timeLengthInClasses} ${classLabel}`;
    }

    return finalLabel;
  }, [course?.timeLengthInClasses, course?.classes, course?.type]);

  const startHours = useMemo(() => {
    const dateString = buildDateStringWithTimeFromStartDateAndStartTime(
      props.cohort?.startDate,
      props.cohort?.classTimeOfDay
    );

    return formatDate(new Date(dateString), TIME_FORMAT);
  }, [props]);

  const totalSeats = props.cohort.maxEnrollment ?? 0;
  const isUnlimitedSeats = totalSeats === 0;
  const totalSeatsLeft = Math.max(0, totalSeats - (props.cohort.totalEnrollments ?? 0));

  const isLimitedSpots =
    !isUnlimitedSeats &&
    props.cohort.type === CohortTypeEnum.LIVE &&
    totalSeatsLeft <= LIMITED_SEATS_THRESHOLD;

  const isFull =
    !isUnlimitedSeats && props.cohort.type === CohortTypeEnum.LIVE && totalSeatsLeft === 0;

  const isTeamEnrollmentVisible = useMemo(() => {
    return userAbilities?.editTeamEnrollments;
  }, [userAbilities?.editTeamEnrollments]);

  const isEnrollVisible = isWaitlistEnabled || isTeamEnrollmentVisible || !isFull;

  useEffect(() => {
    if (!isTeamEnrollmentVisible) return;
    fetchSubordinateEnrollments();
  }, [fetchSubordinateEnrollments, isTeamEnrollmentVisible]);

  const renderContent = () => {
    return (
      <EnrollmentContextProvider
        courseName={course?.name ?? ''}
        cohortId={props.cohort?.id ?? ''}
        cohortSlug={props.cohort?.slug ?? ''}
        cohortStartTime={props.cohort?.classEvents?.[0]?.startTime}
        openForEnrollment={course?.enrollmentStatus === CourseEnrollmentStatusEnum.OPEN}
      >
        {props.managerView ? (
          <Stack
            width="100%"
            padding={{base: 4, lg: 8}}
            spacing={4}
            bg="gray.700"
            alignItems="flex-start"
            direction="row"
            mb={6}
          >
            <VStack alignItems="flex-start" flex={1} spacing={0.3}>
              {props.cohort.type === CohortTypeEnum.LIVE ? (
                <HStack>
                  <Text textTransform="uppercase" color="textColor.200">
                    {startHours}
                  </Text>
                  <Text color="textColor.200"> • </Text>
                  <Text color="textColor.200"> {classesDescription} </Text>
                </HStack>
              ) : null}
              {isTeamEnrollmentVisible && (
                <TeamEnrollmentStatus
                  cohortId={props.cohort?.id}
                  subordinates={subordinateEnrollmentsData?.subordinates}
                />
              )}
            </VStack>
            <VStack alignItems="flex-end">
              {isFull ? (
                <Text color="berry" fontSize="md">
                  This session is full
                </Text>
              ) : isLimitedSpots ? (
                <Text color="lime.300" fontSize="md">
                  Limited spots available!
                </Text>
              ) : null}
            </VStack>
            {isEnrollVisible && (
              <EnrollButton
                cohortId={props.cohort?.id}
                courseType={course?.type}
                variant="outline"
                onEnrollmentChange={handleCloseTeamEnrollModal}
              />
            )}
          </Stack>
        ) : (
          <Stack
            width="100%"
            padding={{base: 4, lg: 8}}
            spacing={6}
            bg="gray.700"
            cursor={props.linkToCourse ? 'pointer' : undefined}
            alignItems={{base: 'flex-start', md: 'center'}}
            direction={{base: 'column', md: 'row'}}
            _hover={props.linkToCourse ? {bg: 'gray.800'} : undefined}
          >
            {props.cohort.type === CohortTypeEnum.LIVE ? (
              <Text textTransform="uppercase">{startHours}</Text>
            ) : null}
            <VStack alignItems="flex-start" flex={1} spacing={0.3}>
              {!props.hideTags && <HStack spacing={4}>{tagsComponents}</HStack>}
              <Heading size="sm" fontSize="20px" textTransform="none" fontWeight="bold">
                {course?.name}
              </Heading>
              <Text color="textColor.200">{classesDescription}</Text>
            </VStack>
            <VStack alignItems="flex-end">
              {isFull ? (
                <Text color="berry" fontSize="md">
                  This session is full
                </Text>
              ) : isLimitedSpots ? (
                <Text color="lime.300" fontSize="md">
                  Limited spots available!
                </Text>
              ) : null}
              {isTeamEnrollmentVisible && (
                <TeamEnrollmentStatus
                  cohortId={props.cohort?.id}
                  subordinates={subordinateEnrollmentsData?.subordinates}
                />
              )}
            </VStack>
            {isEnrollVisible && (
              <EnrollButton
                cohortId={props.cohort?.id}
                courseType={course?.type}
                variant="outline"
                onEnrollmentChange={handleCloseTeamEnrollModal}
              />
            )}
          </Stack>
        )}
      </EnrollmentContextProvider>
    );
  };

  if (props.linkToCourse) {
    return (
      <Link href={coursePath({asUrl: true}).courseDetails(course?.slug)} legacyBehavior passHref>
        <CLink _hover={{textDecoration: 'none'}}>{renderContent()}</CLink>
      </Link>
    );
  } else {
    return renderContent();
  }
}
