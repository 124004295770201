import {Text} from '@chakra-ui/react';
import {FC, useMemo} from 'react';
import {GetSubordinateEnrollmentsQuery} from 'generated/graphql';
import {useAnalytics} from 'context/AnalyticsWebContext';

type SubordinateEnrollments = GetSubordinateEnrollmentsQuery['subordinates'];
interface Props {
  cohortId: string;
  subordinates?: SubordinateEnrollments;
}
export const TeamEnrollmentStatus: FC<Props> = ({cohortId, subordinates}) => {
  const analyticsWebClient = useAnalytics();
  const isWaitlistEnabled = analyticsWebClient?.checkFeatureFlag('enrollment-waitlist');

  const totalEnrolledMembers = useMemo(() => {
    return (
      subordinates?.filter((s) => s.enrollments?.includes(cohortId)).map<string>((s) => s.userId) ??
      []
    ).length;
  }, [subordinates, cohortId]);
  const totalWaitlistedMembers = useMemo(() => {
    return (
      subordinates?.filter((s) => s.waitlists?.includes(cohortId)).map<string>((s) => s.userId) ??
      []
    ).length;
  }, [subordinates, cohortId]);
  const totalEnrolled = totalEnrolledMembers + totalWaitlistedMembers;
  const totalMembers = subordinates?.length ?? 0;

  if (totalMembers === 0) return null;

  return (
    <Text color="white" fontSize="md" style={{marginTop: 0}}>
      {isWaitlistEnabled && totalWaitlistedMembers > 0 ? (
        <>
          {totalEnrolled} / {totalMembers} team member{totalMembers === 1 ? '' : 's'} (
          {totalEnrolledMembers} enrolled & {totalWaitlistedMembers} waitlisted)
        </>
      ) : (
        <>
          {totalEnrolledMembers} / {totalMembers} team member{totalMembers === 1 ? '' : 's'}{' '}
          enrolled
        </>
      )}
    </Text>
  );
};
