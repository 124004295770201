import React, {useMemo} from 'react';

import {Center} from '@chakra-ui/react';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface CheckIndicatorProps {
  size?: 'sm' | 'md' | 'lg';
}

const sizes = {
  sm: '60px',
  md: '70px',
  lg: '80px'
};

const CheckIndicator: React.FC<CheckIndicatorProps> = (props) => {
  const size = useMemo(() => {
    return sizes[props.size ?? 'sm'];
  }, [props.size]);

  return (
    <Center borderRadius="80px" backgroundColor="lime.200" width={size} height={size}>
      <FontAwesomeIcon size="2x" icon={faCheck} color="#0F1015" />
    </Center>
  );
};

export default CheckIndicator;
