import {Heading} from '@chakra-ui/react';
import {Cohorts, CohortTypeEnum, Courses} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import React, {useCallback, useMemo} from 'react';
import {CALENDAR_SPECS} from 'utils/constants';
import {buildDateStringWithTimeFromStartDateAndStartTime} from 'utils/dates/formatDate';
import CohortCard from '.';

interface CohortCardTimeLineProps {
  cohorts: Cohorts[];
  linkToCourse?: boolean;
  course?: Courses;
  hideTags?: boolean;
  onCloseTeamEnrollModal?: () => void;
  managerView?: boolean;
  hideHeader?: boolean;
}

export default function CohortCardTimeLine(props: CohortCardTimeLineProps) {
  const recordedCohorts = useMemo(() => {
    return (props.cohorts || []).filter((cohort) => cohort.type === CohortTypeEnum.RECORDED);
  }, [props.cohorts]);

  const liveCohorts = useMemo(() => {
    return (props.cohorts || []).filter((cohort) => cohort.type === CohortTypeEnum.LIVE);
  }, [props.cohorts]);

  const sortCohorts = useCallback((a: Cohorts, b: Cohorts) => {
    const dateA = new Date(
      buildDateStringWithTimeFromStartDateAndStartTime(a.startDate, a.classTimeOfDay)
    );

    const dateB = new Date(
      buildDateStringWithTimeFromStartDateAndStartTime(b.startDate, b.classTimeOfDay)
    );

    return dateA.getTime() - dateB.getTime();
  }, []);

  return (
    <>
      {_.chain(liveCohorts)
        .groupBy((cohort) => {
          const dateString = buildDateStringWithTimeFromStartDateAndStartTime(
            cohort.startDate,
            cohort.classTimeOfDay
          );
          return moment.utc(dateString).local().calendar(CALENDAR_SPECS);
        })
        .map((dayCohorts) => {
          const dateString = buildDateStringWithTimeFromStartDateAndStartTime(
            dayCohorts[0].startDate,
            dayCohorts[0].classTimeOfDay
          );
          const header = props.managerView ? (
            <Heading
              as="h5"
              size="sm"
              mb="5px"
              color="textColor.200"
              textTransform="capitalize"
              fontSize="16px"
            >
              {moment.utc(dateString).local().calendar(CALENDAR_SPECS)}
            </Heading>
          ) : (
            <Heading as="h5" size="sm" fontSize="14px" color="gray.30">
              {moment.utc(dateString).local().calendar(CALENDAR_SPECS)}
            </Heading>
          );
          return (
            <React.Fragment key={dateString}>
              {props.hideHeader ? null : header}
              {dayCohorts?.sort(sortCohorts).map((cohort) => (
                <CohortCard
                  key={cohort.id}
                  cohort={cohort}
                  course={props.course}
                  linkToCourse={props.linkToCourse}
                  hideTags={props.hideTags}
                  onCloseTeamEnrollModal={props.onCloseTeamEnrollModal}
                  managerView={props.managerView}
                />
              ))}
            </React.Fragment>
          );
        })
        .value()}
      {recordedCohorts?.length > 0 ? (
        <>
          <Heading as="h5" size="sm">
            ON DEMAND
          </Heading>
          {recordedCohorts.map((cohort) => (
            <CohortCard
              key={cohort.id}
              cohort={cohort}
              course={props.course}
              linkToCourse={props.linkToCourse}
              hideTags={props.hideTags}
            />
          ))}
        </>
      ) : null}
    </>
  );
}
