import {FC, ReactElement} from 'react';
import {Heading, VStack, Text, Button, Circle, HeadingProps} from '@chakra-ui/react';
import CheckIndicator from 'components/CheckIndicator';

interface EmptySectionProps {
  title: string;
  subtitle?: string;
  icon?: ReactElement;
  iconVariant?: 'outline' | 'solid';
  ctaLabel?: string;
  ctaIcon?: ReactElement;
  ctaOnClick?: () => void;
  isCompleted?: boolean;
  headingProps?: HeadingProps;
}

const EmptySection: FC<React.PropsWithChildren<EmptySectionProps>> = (props) => {
  return (
    <>
      <VStack width="100%" spacing={6}>
        {props.isCompleted ? <CheckIndicator size="lg" /> : null}

        {!props.isCompleted && props.icon ? (
          <Circle
            size="80px"
            bg={props.iconVariant === 'solid' ? 'lime.200' : 'transparent'}
            color={props.iconVariant === 'solid' ? 'black' : 'lime.200'}
            border="1px solid"
            borderColor="lime.200"
          >
            {props.icon}
          </Circle>
        ) : null}

        <Heading as="h4" fontSize="xl" textTransform="none" margin={0} {...props.headingProps}>
          {props.title}
        </Heading>
        {props.subtitle ? <Text>{props.subtitle}</Text> : null}
        {props.ctaLabel ? (
          <Button variant="outline" onClick={props.ctaOnClick} rightIcon={props.ctaIcon}>
            {props.ctaLabel}
          </Button>
        ) : null}
      </VStack>
    </>
  );
};

export default EmptySection;
