import {PUBLIC_ENV} from 'utils/env/getter';

export function mediaDbUriToCdnConverter(src: string): string {
  src = src.trim();
  // Default media will be served statically from next public folder
  if (src.startsWith('/')) return src;

  const s3Scheme = 's3://';
  const host = PUBLIC_ENV.mediaCdnUrl;

  if (src.startsWith(`s3://sia-production-media-origin`)) {
    return src.replace(/^s3:\/\/([a-zA-Z0-9-]+)/, 'https://images.salesimpact.io');
  }
  if (src.startsWith(`s3://sia-staging-media-origin`)) {
    return src.replace(/^s3:\/\/([a-zA-Z0-9-]+)/, 'https://images.staging.salesimpact.io');
  }
  if (src.startsWith(s3Scheme)) {
    return src.replace(/^s3:\/\/([a-zA-Z0-9-]+)/, host);
  }

  if (src.match(/^https?:\/\//)) {
    return src;
  }

  return host + '/' + src;
}
